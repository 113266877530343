import React from 'react';
import Password from '../../components/Account/Password';
import PrivateRoute from '../../components/PrivateRoute';
import ContextProvider from '../../components/ContextProvider';

const PasswordPage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <PrivateRoute path="/account/password" component={Password} />
  </ContextProvider>
);

export default PasswordPage;
